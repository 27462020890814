

import * as buttonLink from '../../styles/buttonLink.module.scss';
import * as styles from './header.module.scss';
import Collapse from "@material-ui/core/Collapse";
import MenuIcon from '@material-ui/icons/Menu';
import PropTypes from "prop-types"
import React, {useCallback, useState} from "react"
import SeeAvailableButton from "../button/SeeAvailableButton";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import {Link} from "gatsby"
import {vlPages, vxPages} from "../../data/linkData";

const Header = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [vxCollapseOpen, setVxCollapseOpen] = useState(false);
    const [vlCollapseOpen, setVlCollapseOpen] = useState(false);

    const toggleVxCollapse = useCallback(() => {setVxCollapseOpen(prev => !prev)}, []);
    const toggleVxCollapseEnter = useCallback(event => {
        if (event.key === "Enter"){
            setVxCollapseOpen(prev => !prev);
        }
    }, []);

    const toggleVlCollapse = useCallback(() => {setVlCollapseOpen(prev => !prev)}, []);
    const toggleVlCollapseEnter = useCallback(event => {
        if (event.key === "Enter"){
            setVlCollapseOpen(prev => !prev);
        }
    }, []);

    const handleMenuButtonClick = useCallback(() => {
        setMobileMenuOpen(true);
    }, []);

    const handleMenuButtonEnter = useCallback(event => {
        if (event.key === "Enter"){
            setMobileMenuOpen(true);
        }
    }, []);

    return (
        <header>
            <div className={styles.headerWrapper}>
                <Link to="/" className={styles.logoLink}>
                    <span className={styles.logoText}>
                        Benedikte<br/>
                        Vippeextensions
                    </span>
                </Link>
                <nav>
                    <ul className={styles.navList}>
                        <li>
                            <Link to={'/'} className={styles.navLink}>Forside</Link>
                        </li>
                        <li>
                            <Link to={'/vippeextensions'} className={styles.navLink}>Vippeextensions</Link>
                        </li>
                        <li>
                            <Link to={'/vippeloft'} className={styles.navLink}>Vippeløft</Link>
                        </li>
                        <li>
                            <Link to={'/priser'} className={styles.navLink}>Priser</Link>
                        </li>
                        <li>
                            <Link to={'/hvor-er-vi'} className={styles.navLink}>Hvor er vi?</Link>
                        </li>
                        <li>
                            <Link to={'/gavekort'} className={styles.navLink}>Gavekort</Link>
                        </li>
                        <li>
                            <Link to={'/kontakt'} className={styles.navLink}>Kontakt</Link>
                        </li>
                        <li>
                            <Link to={'/jobb-hos-oss'} className={styles.navLink}>Jobb</Link>
                        </li>
                        <li>
                            <Link to={'/booking'} className={buttonLink.buttonLink + ' ' + styles.navBookNow}>Bestill time</Link>
                        </li>
                    </ul>
                    <div
                        role={"button"}
                        className={styles.navHamburger}
                        onClick={handleMenuButtonClick}
                        onKeyDown={handleMenuButtonEnter}
                        tabIndex={0}
                    >
                        <MenuIcon/>
                    </div>
                    <SwipeableDrawer
                        onClose={() => {setMobileMenuOpen(false)}}
                        onOpen={() => {setMobileMenuOpen(true)}}
                        open={mobileMenuOpen}
                        anchor={"right"}
                        classes={{
                            paper: styles.drawer
                        }}
                    >
                        <ul className={styles.mobileMenuList}>
                            <li>
                                <Link to={'/'} className={styles.mobileMenuLink}>Forside</Link>
                            </li>
                            <li
                                role={"button"}
                                onClick={toggleVxCollapse}
                                onKeyDown={toggleVxCollapseEnter}
                                tabIndex={0}
                            >
                                <span className={styles.mobileMenuLink}>Vippeextensions</span>
                            </li>
                            <Collapse in={vxCollapseOpen}>
                                <ul className={styles.navSublist}>
                                    {
                                        vxPages.map((page, index) => (
                                            <li key={index.toString()}>
                                                <Link to={page.path} className={styles.mobileMenuLink + ' ' + styles.navSublistLink}>{ page.label }</Link>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </Collapse>
                            <li role={"button"} onClick={toggleVlCollapse} onKeyDown={toggleVlCollapseEnter} tabIndex={0}>
                                <span className={styles.mobileMenuLink}>Vippeløft</span>
                            </li>
                            <Collapse in={vlCollapseOpen}>
                                <ul className={styles.navSublist}>
                                    {
                                        vlPages.map((page, index) => (
                                            <li key={index.toString()}>
                                                <Link to={page.path} className={styles.mobileMenuLink + ' ' + styles.navSublistLink}>{ page.label }</Link>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </Collapse>
                            <li>
                                <Link to={'/priser'} className={styles.mobileMenuLink}>Priser</Link>
                            </li>
                            <li>
                                <Link to={'/hvor-er-vi'} className={styles.mobileMenuLink}>Hvor er vi?</Link>
                            </li>
                            <li>
                                <Link to={'/gavekort'} className={styles.mobileMenuLink}>Gavekort</Link>
                            </li>
                            <li>
                                <Link to={'/kontakt'} className={styles.mobileMenuLink}>Kontakt</Link>
                            </li>
                            <li>
                                <Link to={'/jobb-hos-oss'} className={styles.mobileMenuLink}>Jobb hos oss</Link>
                            </li>
                        </ul>
                        <SeeAvailableButton
                            to={"/booking"}
                            margin={"small"}
                            textAlign={"left"}
                        >
                            Bestill Time
                        </SeeAvailableButton>
                    </SwipeableDrawer>
                </nav>
            </div>
            {/*<Link to={'/booking'} className={styles.coronaBannerLink}>*/}
            {/*    <div className={styles.coronaBanner}>*/}
            {/*        Vi er åpne igjen! Velkommen!*/}
            {/*    </div>*/}
            {/*</Link>*/}
        </header>
    )
};

Header.propTypes = {
    siteTitle: PropTypes.string,
};

Header.defaultProps = {
    siteTitle: ``,
};

export default Header;
