
import * as styles from './footer.module.scss';
import ContentMargins from "../layout/ContentMargins";
import React from 'react';
import { Link } from 'gatsby';

const Footer = () => (
    <div className={styles.footerWrapper}>
        <ContentMargins>
            <div className={styles.footerContent}>
                <p><Link to={'/personvern'} className={styles.footerLink}>Personvernerklæring</Link></p>
                <br/>
                <p><Link to={'/cookies'} className={styles.footerLink}>Informasjonskapsler</Link></p>
                <br/>
                <p>Benedikte Vippeextensions AS</p>
                <p>Organisasjonsnummer: 818 687 362 MVA</p>
                <p>Foretaksregisteret</p>
                <br/>
                <p>Søndre Gate 2</p>
                <p>0550 Oslo</p>
                <p><a href={'mailto:kontakt@benediktevippeextensions.no'}
                      className={styles.footerLink}>kontakt@benediktevippeextensions.no</a></p>
                <br/>
                <div className={styles.footerCopyright}>
                    <p>Alt innhold tilhører Benedikte Vippeextensions AS og kan ikke gjengis uten tillatelse.</p>
                </div>
            </div>
        </ContentMargins>
    </div>
);

export default Footer;