
import * as styles from './button.module.scss';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'gatsby';

const Button = ({to, children, className}) => (
    <Link to={to} className={styles.button + (className ? ' ' + className : '')}>
        { children }
    </Link>
);

Button.propTypes = {
    to: PropTypes.string.isRequired,
    className: PropTypes.string
};

export default Button;