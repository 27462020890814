// extracted by mini-css-extract-plugin
export var active = "layout-custom-module--active--DGuSr";
export var coronaBanner = "layout-custom-module--corona-banner--kWikm";
export var coronaBannerLink = "layout-custom-module--corona-banner-link--I7SDs";
export var drawer = "layout-custom-module--drawer--tuUZH";
export var headerLogo = "layout-custom-module--header-logo--TVSDv";
export var headerWrapper = "layout-custom-module--header-wrapper--Z3h6z";
export var hover = "layout-custom-module--hover--fupg8";
export var logoLink = "layout-custom-module--logo-link--B2NQN";
export var logoText = "layout-custom-module--logo-text--Ks5A1";
export var mobileMenuLink = "layout-custom-module--mobile-menu-link--O7GOH";
export var mobileMenuList = "layout-custom-module--mobile-menu-list--GuZFu";
export var navBookNow = "layout-custom-module--nav-book-now--+yAaP";
export var navHamburger = "layout-custom-module--nav-hamburger--N-2FP";
export var navLink = "layout-custom-module--nav-link--0ZZyH";
export var navList = "layout-custom-module--nav-list--2ECbl";
export var navSublist = "layout-custom-module--nav-sublist--h144e";
export var navSublistLink = "layout-custom-module--nav-sublist-link--7q3oV";
export var wrapper = "layout-custom-module--wrapper--lMS42";