
import * as styles from './see-available-button.module.scss';
import Button from "./Button";
import React from 'react';

const SeeAvailableButton = ({ children, margin, textAlign, className }) => {
    let cls, alignCls;
    if (margin === "small"){
        cls = styles.wrapperSmallMargin;
    } else {
        cls = styles.wrapper;
    }

    if (textAlign === "left"){
        alignCls = styles.alignLeft;
    } else {
        alignCls = styles.alignCenter;
    }

    return(
        <div className={`${cls} ${alignCls} ${className || ''}`}>
            <Button to={"/booking"}>{ children }</Button>
        </div>
    );
};

export default SeeAvailableButton;