// extracted by mini-css-extract-plugin
export var active = "header-module--active--byAzz";
export var coronaBanner = "header-module--corona-banner--kwENl";
export var coronaBannerLink = "header-module--corona-banner-link--lTXM8";
export var drawer = "header-module--drawer--PV+WW";
export var headerLogo = "header-module--header-logo--AB2Gh";
export var headerWrapper = "header-module--header-wrapper--ggfd+";
export var hover = "header-module--hover--qRIeF";
export var logoLink = "header-module--logo-link--+Pia8";
export var logoText = "header-module--logo-text--qWov8";
export var mobileMenuLink = "header-module--mobile-menu-link--gRVeH";
export var mobileMenuList = "header-module--mobile-menu-list--fxUGf";
export var navBookNow = "header-module--nav-book-now--feFlv";
export var navHamburger = "header-module--nav-hamburger--HUIvq";
export var navLink = "header-module--nav-link--cIRbR";
export var navList = "header-module--nav-list--kfet3";
export var navSublist = "header-module--nav-sublist--zqpm+";
export var navSublistLink = "header-module--nav-sublist-link--WCNgc";