/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import "../../styles/_breakpoints.scss";
import "../../styles/root.scss";
import "./layout.css";
import * as styles from './layout-custom.module.scss';
import Footer from "../footer/Footer";
import Header from "../header/header";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import visit from "../../data/visit";
import CookieBanner from "../CookieBanner/CookieBanner";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";

const theme = createTheme({
    typography: {
        fontFamily: 'Verdana, sans-serif',
    }
});

const Layout = ({children}) => {
    useEffect(() => {
        visit();
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <Header/>
            <div className={styles.wrapper}>
                <div>
                    <main>
                        { children }
                    </main>
                </div>
                <div>
                    <footer>
                        <Footer/>
                    </footer>
                </div>
            </div>
            <CookieBanner />
        </ThemeProvider>
    )
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
