/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import Helmet from "react-helmet"
import PropTypes from "prop-types"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"

function Seo({ description, meta, title, extraTags }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang: 'no'
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:site_name`,
          content: `Benedikte Vippeextensions`
        },
        {
          property: `og:image`,
          content: `/meta_share/mural_high_100q.jpg`
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: "Benedikte Villmark",
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      {/*<link rel="stylesheet" href="https://use.typekit.net/coj4dxs.css" />*/}
      <script>
        {`
          WebFontConfig = {
            typekit: {
              id: "coj4dxs"
            }
          };
          
          (function(d) {
            var wf = d.createElement('script'), s = d.scripts[0];
            wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js';
            wf.async = true;
            s.parentNode.insertBefore(wf, s);
          })(document);
        `}
      </script>
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-CW6BZE4FDT"></script>
      <script type="text/javascript">
        {`
          // Google
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('consent', 'default', {
            'ad_storage': 'denied',
            'ad_user_data': 'denied',
            'ad_personalization': 'denied',
            'analytics_storage': 'denied',
            'wait_for_update': 500
          });
          
          gtag('js', new Date());
          gtag('config', 'G-CW6BZE4FDT');  // Google Analytics 4
          gtag('config', 'AW-859578284');  // Google Ads
        `}
      </script>
      <script type="text/javascript">
        {`
        // Facebook
          !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('consent', 'revoke');
        fbq('init', '759361831066148');
        fbq('track', 'PageView');
        `}
      </script>
      <noscript>
        {`
            <img
              height="1"
              width="1"
              style="display:none"
              src="https://www.facebook.com/tr?id=759361831066148&ev=PageView&noscript=1"/>
        `}
      </noscript>
      { extraTags ? extraTags : null }
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `no`,
  meta: [],
  description: ``,
};

Seo.propTypes = {
  description: PropTypes.string.isRequired,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default Seo
