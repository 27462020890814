const vxPages = [
    {path: "/vippeextensions", label: "Hva er vippeextensions"},
    {path: "/vippeextensionsvarianter", label: "Vippeextensions-varianter"},
    {path: "/rens-av-vippeextensions", label: "Rens av vippeextensions"},
    {path: "/kan-jeg-bruke-sminke", label: "Kan jeg bruke sminke?"},
    {path: "/pfyll-fra-andre-steder", label: "Påfyll fra andre salonger"},
];

const vlPages = [
    {path: "/vippeloft", label: "Hva er vippeløft"},
    {path: "/vippeloft-vs-vippeextensions", label: "Vippeløft vs. vippeextensions"},
];

export { vxPages, vlPages };