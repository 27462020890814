import React, {useCallback, useEffect, useState} from 'react';
import * as cookieBannerStyles from "../../styles/cookie-banner.module.scss";
import {Link} from "gatsby";
import Button from "@material-ui/core/Button";
import {Fade} from "@material-ui/core";

const localStorageAvailable = () => {
    // Return false during build process
    if (typeof window === "undefined") return false;

    const test = 'local_storage_test';
    try {
        sessionStorage.setItem(test, test);
        sessionStorage.removeItem(test);
        return true;
    } catch (err) {
        console.error("LocalStorage error");
        return false;
    }
};

const CookieBanner = () => {
    // Set default state depending on localStorage
    const [displayCookieBanner, setDisplayCookieBanner] = useState(() => {
        if (!localStorageAvailable()){
            return true;  // Eg. build or horrible browser
        }

        if (window.localStorage.getItem('cookieBannerDismissed_rev2') === "dismissed"){
            return false;
        }

        return true;
    });

    // If state is saved from last visit, update gtag with consent data
    useEffect(() => {
        if (
            localStorageAvailable() &&
            window.localStorage.getItem('cookieBannerDismissed_rev2') === "dismissed"
        ){
            setDisplayCookieBanner(false);

            try {
                const consentDataString = window.localStorage.getItem('cookieConsent_rev2');
                if (!consentDataString) return;

                const consentDataRaw = JSON.parse(consentDataString);
                const consentData = {};
                ['ad_user_data', 'ad_personalization', 'ad_storage', 'analytics_storage'].forEach(key => {
                    if (consentDataRaw.hasOwnProperty(key) && typeof consentDataRaw[key] === 'boolean'){
                        consentData[key] = consentDataRaw[key] ? 'granted' : 'denied';
                    }
                });

                if (Object.keys(consentData).length > 0){
                    window.gtag('consent', 'update', consentData);
                    window.fbq('consent', 'grant');
                }
            } catch (err){
                console.error(err);
            }
        }
    }, []);


    const acceptAllCookies = useCallback(() => {
        setDisplayCookieBanner(false);
        if (localStorageAvailable()){
            window.localStorage.setItem('cookieBannerDismissed_rev2', "dismissed");
            window.localStorage.setItem('cookieConsent_rev2', JSON.stringify({
                ad_user_data: true,
                ad_personalization: true,
                ad_storage: true,
                analytics_storage: true
            }));
        }

        window.gtag('consent', 'update', {
            ad_user_data: 'granted',
            ad_personalization: 'granted',
            ad_storage: 'granted',
            analytics_storage: 'granted'
        });
        window.fbq('consent', 'grant');
    }, []);

    const denyAllCookies = useCallback(() => {
        setDisplayCookieBanner(false);
        if (localStorageAvailable()){
            window.localStorage.setItem('cookieBannerDismissed_rev2', "dismissed");
            window.localStorage.setItem('cookieConsent_rev2', JSON.stringify({
                ad_user_data: false,
                ad_personalization: false,
                ad_storage: false,
                analytics_storage: false
            }));
        }

        window.gtag('consent', 'update', {
            ad_user_data: 'denied',
            ad_personalization: 'denied',
            ad_storage: 'denied',
            analytics_storage: 'denied'
        });
        window.fbq('consent', 'revoke');
    }, []);

    return(
        <Fade in={displayCookieBanner}>
            <div className={cookieBannerStyles.cookieBanner}>
                <div className={cookieBannerStyles.cookieHeading}>
                    Bruk av informasjonskapsler
                </div>
                <div className={cookieBannerStyles.cookieText}>
                    Om du tillater det ønsker vi å bruke informasjonskapsler til å måle og forbedre markedsføringen vår.
                    Du kan også senere endre samtykket ditt <Link to={'/cookies'}>her</Link> og lese vår personvernerklæring <Link to={'/personvern'}>her</Link>.
                </div>
                <div className={cookieBannerStyles.cookieButtonWrapper}>
                    <Button
                        variant={"contained"}
                        className={cookieBannerStyles.cookieButton}
                        onClick={acceptAllCookies}
                    >
                        Godta alle
                    </Button>
                    <Button
                        variant={"contained"}
                        className={cookieBannerStyles.cookieButton}
                        onClick={denyAllCookies}
                    >
                        Avvis alle
                    </Button>
                </div>
            </div>
        </Fade>
    );
};

export default CookieBanner;