const sessionStorageAvailable = () => {
    // Return false during build process
    if (typeof window === "undefined") return false;

    const test = 'local_storage_test';
    try {
        sessionStorage.setItem(test, test);
        sessionStorage.removeItem(test);
        return true;
    } catch (err) {
        console.error("LocalStorage error");
        return false;
    }
};

const visit = () => {
    // During server side rendering, return null
    if (typeof window === "undefined") return null;

    // If we've already calculated the visit, return it
    if (window.visit){
        return window.visit;
    }

    try {
        if (!sessionStorageAvailable()){
            window.visit = null;
            return;
        }

        const session = sessionStorage.getItem('visit');
        let value;

        if (session){
            const pv = JSON.parse(session);
            if (
                pv.hasOwnProperty('ts') && typeof pv.ts === 'number' && pv.ts > 0 &&
                pv.hasOwnProperty('landing_url') && typeof pv.landing_url === 'string' && pv.landing_url.length > 0
            ){
                value = {
                    ts: pv.ts,
                    landing_url: pv.landing_url
                };
            }
        }

        if (!value){
            value = {
                ts: Date.now(),
                landing_url: window.location.href
            };
            sessionStorage.setItem('visit', JSON.stringify(value));
        }

        window.visit = value;
    } catch (e) {
        console.error(e);
        window.visit = null;
    }

    return window.visit;
};

export default visit;