
import * as styles from './ContentMargins.module.scss';
import React from 'react';

const ContentMargins = ({ children, className, topMargin }) => {
    return(
        <div className={styles.contentMargins + (className ? ` ${className}` : '') + (topMargin ? ` ${styles.topMargin}` : '')}>
            { children }
        </div>
    );
};

export default ContentMargins;
